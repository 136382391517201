import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Container, Title, Input, Button, List, ListItem, ErrorMessage} from '../styles';
import {BASE_URL} from "../Constants";
import {ApiClient, ApiResponse} from "../Network";
import {AuthorModel} from "../model/AuthorModel";
import Switch from "react-switch";

var apiClient = ApiClient.getInstance()

const AuthorComponent: React.FC = () => {
    const [authors, setAuthors] = useState<AuthorModel[]>([]);
    const [name, setName] = useState<string>('');
    const [bio, setBio] = useState<string>('');
    const [language, setLanguage] = useState<string>('ru');
    const [isRu, setIsRu] = useState<boolean>(false);
    const [id, setId] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await apiClient.get<AuthorModel[]>('authors');
            setAuthors(response.data.data);
        } catch (error) {
            console.error('Error fetching author', error);
            setError('Error fetching author')
        }
    };

    const create = async () => {
        try {
            await apiClient.post<String>('author', {
                name,
                bio,
                language
            });
            fetchData()
            setName('');
            setBio('');
        } catch (error) {
            console.error('Error creating author', error);
            setError('Error creating author')
        }
    };

    const update = async () => {
        if (id === null) return;
        try {
            await apiClient.put<String>(`author/${id}`, {
                    name,
                    bio,
                    language
                }
            );
            fetchData();
            setName('');
            setBio('');
            setId(null);
        } catch (error) {
            console.error('Error updating author', error);
            setError('Error updating author')

        }
    };

    const deleteAuthor = async (id: string) => {
        try {
            await apiClient.del<String>(`author/${id}`);
            fetchData();
        } catch (error) {
            console.error('Error deleting author', error);
            setError('Error deleting author')

        }
    };

    return (
        <Container>
            <Title>Authors</Title>
            <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Author Name"
            />
            <Input
                type="text"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                placeholder="Bio"
            />
            <Switch
                checked={isRu}
                onChange={
                    () => {
                        let lang = "ru"

                        if (!isRu) {
                            lang = "en"
                        }

                        setLanguage(lang)
                        setIsRu(!isRu)
                    }
                }
                title={language}
                name={language}
            />
            Lang {language}
            <Button onClick={id === null ? create : update}>
                {id === null ? 'Create' : 'Update'}
            </Button>

            {error && <ErrorMessage>{error}</ErrorMessage>}

            <List>
                {Array.isArray(authors) && authors.map((item) => (
                    <ListItem key={item.id}>
                        (ID: {item.id}) (Bio : {item.bio}) {item.name}  {item.language}
                        <div>
                            <Button onClick={() => {
                                setName(item.name);
                                setBio(item.bio);
                                setId(item.id);
                                setLanguage(item.language)
                            }}>Edit</Button>
                            <Button onClick={() => deleteAuthor(item.id)}>Delete</Button>
                        </div>
                    </ListItem>
                ))}
            </List>
        </Container>
    );
};

export default AuthorComponent;

export {}; // Add this line to ensure the file is treated as a module
