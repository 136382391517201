import React, {useState, useEffect} from 'react';
import {Container, Title, Input, Button, List, ListItem, ErrorMessage} from '../styles';
import {ApiClient} from "../Network";
import {CategoryResultModel} from "../model/CategoryModel";
import {getAllCategoryResults} from "../usecase/GetCategoryResultsUseCase";
import Switch from "react-switch";

const apiClient = ApiClient.getInstance();

const CategoryComponent: React.FC = () => {
    const [categoryResults, setResults] = useState<CategoryResultModel[]>([]);
    const [name, setName] = useState<string>('');
    const [type_id, setType_id] = useState<number | null>(null);
    const [group_id, setGroup_id] = useState<number | null>(null);
    const [id, setId] = useState<number | null>(null);
    const [is_premium, setIsPremium] = useState<boolean>(false);
    const [language, setLanguage] = useState<string>('ru');
    const [isRu, setIsRu] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const results = await getAllCategoryResults()
            if (results)
                setResults(results);
        } catch (error) {
            console.error('Error fetching component types', error);
            setError('Error fetching component type');
        }
    };

    const createCategoryType = async () => {
        try {
            await apiClient.post<String>('category', {
                name,
                type_id,
                group_id,
                is_premium,
                language
            });
            getData()
            setName('');
            setType_id(null);
            setGroup_id(null)
            setIsPremium(false)
        } catch (error) {
            console.error('Error creating component', error);
            setError('Error creating component')
        }
    };

    const updateCategoryType = async () => {
        if (id === null) return;
        try {
            await apiClient.put<String>(`category/${id}`, {
                    name,
                    type_id,
                    group_id,
                    is_premium,
                    language
                }
            );
            getData();
            setName('');
            setType_id(null);
            setId(null);
            setGroup_id(null);
            setIsPremium(false)
        } catch (error) {
            console.error('Error updating component', error);
            setError('Error updating component')
        }
    };

    const deleteCategoryType = async (id: number) => {
        try {
            await apiClient.del<String>(`category/${id}`);
            getData();
        } catch (error) {
            console.error('Error deleting component', error);
            setError('Error deleting component')

        }
    };

    return (
        <Container>
            <Title>Categories</Title>
            <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Category Name"
            />
            <Input
                type="number"
                value={type_id || ''}
                onChange={(e) => setType_id(Number(e.target.value))}
                placeholder="Type ID"
            />
            <Input
                type="number"
                value={group_id || ''}
                onChange={(e) => setGroup_id(Number(e.target.value))}
                placeholder="Group ID"
            />
            <Switch
                checked={is_premium}
                onChange={() => setIsPremium(!is_premium)}
                title={'is Premium'}
            />
            Premium {is_premium.toString()}
            <Switch
                checked={isRu}
                onChange={
                    () => {
                        let lang = "ru"

                        if (!isRu) {
                            lang = "en"
                        }

                        setLanguage(lang)
                        setIsRu(!isRu)
                    }
                }
                title={language}
                name={language}
            />
            Lang {language}
            <Button onClick={id === null ? createCategoryType : updateCategoryType}>
                {id === null ? 'Create' : 'Update'}
            </Button>

            {error && <ErrorMessage>{error}</ErrorMessage>}

            <List>
                {Array.isArray(categoryResults) && categoryResults.map((item) => (
                    <ListItem key={item.id}>
                        <div>
                            <CategoryItem key={item.id} category={item}/> {item.language}
                        </div>
                        <div>
                            <Button onClick={() => {
                                setName(item.name);
                                setType_id(item.type_id);
                                setGroup_id(item.group_id);
                                setId(item.id);
                                setIsPremium(item.is_premium)
                                setLanguage(item.language)
                            }}>Edit</Button>
                            <Button onClick={() => deleteCategoryType(item.id)}>Delete</Button>
                        </div>
                    </ListItem>
                ))}
            </List>
        </Container>
    );
};

export const CategoryItem: React.FC<{ category: CategoryResultModel }> = ({category}) => (
    <div>
        {category.group_id && (
            <>
                <strong>Group:</strong> {category.group_name} (ID: {category.group_id})<br/>
            </>
        )}

        {category.type_id && (
            <>
                <ul>
                    <li><strong>Type:</strong> {category.type_name} (ID: {category.type_id})</li>

                    <ul>
                        <li><strong>Category: </strong> {category.name} (ID: {category.id}) (Is
                            Premium: {category.is_premium.toString()})
                        </li>
                    </ul>
                </ul>
            </>
        )}

        {!category.type_id && (
            <>
                <ul>
                    <li><strong>Category: </strong> {category.name} (ID: {category.id}) (Is
                        Premium: {category.is_premium.toString()})
                    </li>
                </ul>
            </>
        )}
    </div>
);

export default CategoryComponent;

export {}; // Add this line to ensure the file is treated as a module
